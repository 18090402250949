import axios from "axios";

const api = axios.create({ baseURL: process.env.REACT_APP_SERVER_URL });

api.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  // const token = JSON.parse(JSON.parse(localStorage['persist:session']).auth).token;
  config.headers["Authorization"] = token ? `Bearer ${token}` : undefined;
  return config;
});

export default api;
