import { ethers } from "ethers";
import api from "../../api";
import endpoints from "../../api/endpoints";
import { metamaskProvider } from "../../blockchain/walletConnect";

//==================== Login call ====================//
export const authenticateUser = (data) => async (dispatch) => {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && !window.ethereum) {
    window.location.href = `https://metamask.app.link/dapp/${process.env.REACT_APP_CHAIN_ID === "80001" ? "demo" : "app"}.minty.art`;
  }
  const ethRes = await window.ethereum.request({ method: "eth_requestAccounts" });
  const address = ethers.utils.getAddress(ethRes[0]);
  const provider = await metamaskProvider();
  const signer = provider.getSigner();
  const ntw = await provider.getNetwork();
  if(ntw.chainId == process.env.REACT_APP_CHAIN_ID){
    const userSigninRequest = await api.post(endpoints.PostUserSignin, { address, isAdmin: true });
    const nonce = userSigninRequest.data.nonce;
    const signature = await signer.signMessage(nonce);
    const userVerifyRequest = await api.post(endpoints.PostUserVerify, { address, signature, isAdmin: true });
    localStorage.setItem("token", userVerifyRequest.data.token);
    dispatch({
      type: "LOGIN_USER",
      payload: { ...userVerifyRequest.data, address },
    });

  }else {
    alert('Choose right network')
  }
  //   const userExist = await api.post("/user/user-exists", { address });
 
};

//==================== Logout User ====================//
export const logoutUser = (data) => async (dispatch) => {
  window.localStorage.clear();

  return {
    type: "LOGOUT_USER",
  };
};

