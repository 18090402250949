import api from "../../api";
import endpoints from "../../api/endpoints";


export const getCoinGeckoApiData = (data) => async (dispatch) => {
    // this data is not need to dispatch to to stor so we can simpally return is 
    const res = await api.get(endpoints.coinGeckoApi);
    // console.log("=>",res?.data?.ethereum.usd);
    dispatch({
        type: "COINGECKO_ETH",
        payload: res.data.ethereum.usd,
      });
  };