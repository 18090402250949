// material
import React, { useEffect } from "react";
import { Box, Grid, Container, Typography, CardHeader, Card, Divider, Stack, TextField, Button } from "@mui/material";
import { ethers } from "ethers";
// components
import Page from "../components/Page";
import Tabs from "../myComponents/CustomTabs";
import { getContracts } from "../blockchain";
import { addresses,abis } from "../blockchain/factory";
import { bigNumberToDecimal, isCorrectNetwork } from "../blockchain/utils";
import Loading from "../myComponents/Loader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// ----------------------------------------------------------------------

export default function Mint() {
  const [mintAmount, setMintAmount] = React.useState("");
  const [burnAmount, setBurnAmount] = React.useState("");
  const [isLoadingBurn, setIsLoadingBurn] = React.useState(false);
  const [isLoadingMint, setIsLoadingMint] = React.useState(false);
  const [balance, setBalance] = React.useState("");

  useEffect(() => {
    checkBalance();
  }, [balance]);

  const checkBalance = async () => {
    const { contract, address,provider } = await getContracts(addresses.pecs, abis.pecs);
    const network = await provider.getNetwork();
    if(network.name === process.env.REACT_APP_NETWORK_L1){
      let pecsBalance = await contract.balanceOf(address);
      pecsBalance = bigNumberToDecimal(pecsBalance);
      setBalance(pecsBalance);
    }
  };

  const handleMint = async () => {
    try {
      const { contract, address } = await getContracts(addresses.pecs, abis.pecs);
      const isCorrectNet = await isCorrectNetwork(process.env.REACT_APP_NETWORK_L1); 
      if (!isCorrectNet) toast.info("Please choose L1 network to mint");
      if (!mintAmount) toast.info("Enter amount to mint");
      

      if (isCorrectNet&& mintAmount) {
        setIsLoadingMint(true);
        const res = await contract.mint(address, ethers.utils.parseEther(mintAmount));
        await res.wait();
        setMintAmount("");
        setIsLoadingMint(false);
        toast.success(`${mintAmount} tokens minted...`);
        checkBalance();
        
      } 
    } catch (error) {
      console.log(error);
      setMintAmount("");
      setIsLoadingMint(false);
    }
  };

  const handleBurn = async () => {
    try {
      const { contract, address } = await getContracts(addresses.pecs, abis.pecs);
      const isCorrectNet = await isCorrectNetwork(process.env.REACT_APP_NETWORK_L1); 
      if (!isCorrectNet) toast.info("Please choose L1 network to burn");
      if (!burnAmount) toast.info("Enter amount");

      if (isCorrectNet && burnAmount) {
        setIsLoadingBurn(true);
        const res = await contract.burnTokens(ethers.utils.parseEther(burnAmount));
        await res.wait();
        setBurnAmount("");
        setIsLoadingBurn(false);
        toast.success(`${burnAmount} tokens burnt...`);
        checkBalance()
      }

    } catch (error) {
      console.log(error);
      setBurnAmount("");
      setIsLoadingBurn(false);
    }
  };
  const handleAmountChange = (e) => {
    setBurnAmount("");
    setMintAmount(e.target.value);
  };
  const handleBurnChange = (e) => {
    setMintAmount("");
    setBurnAmount(e.target.value);
  };

  return (
    <Page title="Minting page">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4" >PECS Balance: {balance} </Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            <Card>
              <Stack mb={5} mt={2} direction="column" alignItems="center" justifyContent="center" spacing={3}>
                <CardHeader title="Mint PECS" />
                <TextField type="number" onChange={handleAmountChange} value={mintAmount} placeholder="Minting amount"></TextField>
                <Button onClick={handleMint} variant="contained" to="#" size="large" endIcon={isLoadingMint && <Loading />} disabled={isLoadingMint}>
                  Mint
                </Button>
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Card>
              <Stack mb={5} mt={2} direction="column" alignItems="center" justifyContent="center" spacing={3}>
                <CardHeader title="Burn PECS" />
                <TextField type="number" onChange={handleBurnChange} value={burnAmount} placeholder="Burning amount"></TextField>
                <Button onClick={handleBurn} variant="contained" to="#" size="large" endIcon={isLoadingBurn && <Loading />} disabled={isLoadingBurn}>
                  Burn
                </Button>
              </Stack>
            </Card>
          </Grid>
        </Grid>
        <ToastContainer />
      </Container>
    </Page>
  );
}
