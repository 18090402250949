import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import reducers from "./reducers";

const persistConfig = {
  key: "session",
  storage: storage,
  whitelist: ["auth"], // which reducer want to store in persitent storage
};
const pReducer = persistReducer(persistConfig, reducers);

const store = createStore(pReducer, composeWithDevTools(applyMiddleware(thunk)));
const persistedStore = persistStore(store);

export { persistedStore, store };
