// material
import React, { useEffect } from "react";
import { Grid, Container, CardHeader, Typography, Card, FormGroup, FormControlLabel, Stack, TextField, Button, FormHelperText, Checkbox, Box } from "@mui/material";
import { ethers } from "ethers";
// components
import Page from "../components/Page";
import { getContracts, getSigner } from "../blockchain";
import { pecsAbi, manHattenAbi } from "../blockchain/abi";
import { addresses, abis } from "../blockchain/factory";

import { bigNumberToDecimal, isCorrectNetwork, decimalToBigNumber } from "../blockchain/utils";
import Loading from "../myComponents/Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// ----------------------------------------------------------------------

export default function Transfer() {
  const [transferAmount, setTransferAmount] = React.useState("");
  const [addr, setAddr] = React.useState("");
  const [isLoadingMint, setIsLoadingMint] = React.useState(false);
  const [balance, setBalance] = React.useState("");
  const [senderBalance, setSenderBalance] = React.useState("");
  const [isChecked, setIsChecked] = React.useState(false);
  const [eth, setEth] = React.useState('');
  const [recommendedVal, setRecommendedVal] = React.useState('');

  useEffect(() => {
    checkBalance();
    getRecommendedValue();
  }, [balance]);

  const checkBalance = async (ad) => {
    const { contract, address, provider } = await getContracts(addresses.pecs, abis.pecs);
    const network = await provider.getNetwork();
    if (network.name === process.env.REACT_APP_NETWORK_L2) {
      let pecsBalance = await contract.balanceOf(address);
      pecsBalance = bigNumberToDecimal(pecsBalance);
      setSenderBalance(pecsBalance);
    }
  };

  const getRecommendedValue =async()=>{
    const {signer} = await getSigner();
    const manHatten = new ethers.Contract(process.env.REACT_APP_MANHATTEN_CONTRACT_L2, manHattenAbi, signer);
    let val = await manHatten.minimum_value();
    val = bigNumberToDecimal(val);
    setRecommendedVal(val);
  }

  const handleTransfer = async () => {
    try {
      debugger
      // 0xcfa706a2083355565469F68E3372D53A060C9BeE
      // 0x77f38012590497228Cf9b82E6174ce7539F0945B
      const { contract, signer, address } = await getContracts(addresses.pecs, abis.pecs);
      const manHatten = new ethers.Contract(process.env.REACT_APP_MANHATTEN_CONTRACT_L2, manHattenAbi, signer);
      const isCorrectNet = await isCorrectNetwork(process.env.REACT_APP_NETWORK_L2);
      if (!isCorrectNet) toast.info("Please choose L2 network to mint");
      if (!transferAmount) toast.info("Enter amount to transfer");

      if (isCorrectNet && transferAmount && addr) {
        setIsLoadingMint(true);
        let recommendedValBig = await manHatten.recommended_value(addr);
        const recommendedValue = bigNumberToDecimal(recommendedValBig);
        const isOperatorFor = await contract.isOperatorFor(process.env.REACT_APP_MANHATTEN_CONTRACT_L2, address);
        if (!isOperatorFor) {
          toast.info("Please confirm two transaction first is one time confirmation to set you as an operator");
          await contract.authorizeOperator(process.env.REACT_APP_MANHATTEN_CONTRACT_L2);
        }

        if (isChecked && recommendedValue <= recommendedVal && recommendedValue != "0.0") {
          await manHatten.transfer(addr, decimalToBigNumber(transferAmount), { value: recommendedValBig });
        } else if (!isChecked && eth !==''){
          await manHatten.transfer(addr, decimalToBigNumber(transferAmount), { value: decimalToBigNumber(eth) });
        } 
        else {
          const res = await contract.transfer(addr, decimalToBigNumber(transferAmount), { gasLimit: 100000 });
          await res.wait();
        }

        setTransferAmount("");
        setAddr("");
        setBalance("");
        setEth('');
        setIsLoadingMint(false);
        setIsChecked(false);
        toast.success(`${transferAmount} tokens transfered to given address`);
        await checkBalance();
      }
    } catch (error) {
      console.log(error);
      setTransferAmount("");
      setAddr("");
      setIsLoadingMint(false);
      setBalance("");
      setIsChecked(false);
    }
  };

  const handleAmountChange = (e) => {
    setTransferAmount(e.target.value);
  };
  const handleEthChange = (e) => {
    setEth(e.target.value);
    if(e.target.value) setIsChecked(false);

  };

  const handleAddressChange = async (e) => {
    try {
      setAddr(e.target.value);
      if (e.target.value === "") {
        setBalance('')
        }else {
        const { provider } = await getSigner();
        let ethBalance = await provider.getBalance(e.target.value);
        if(ethBalance){
          ethBalance = bigNumberToDecimal(ethBalance);
          setBalance(ethBalance);
        } 
        
      }
    } catch (error) {
      toast.warning(error.reason)
      setBalance('')
    }
    
    
  };
  const handleCheckBox = (e) => {
    setIsChecked(e.target.checked);
  };

  return (
    <Page title="Minting page">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Sender Balance PECS: {senderBalance} </Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Card style={{ padding: 20 }}>
              <CardHeader title="Transfer PECS" />
              <Stack pl={1} pr={1} pb={1} mt={1} direction="row" alignItems="center" justifyContent="space-around" spacing={1}>
                <TextField onChange={handleAddressChange} value={addr} placeholder="Address" style={{ width: "44%" }}></TextField>
                <TextField onChange={handleAmountChange} value={transferAmount} placeholder="PECS Amount" style={{ width: "13%" }} ></TextField>
                <TextField onChange={handleEthChange} value={eth} placeholder="Eth Amount" style={{ width: "13%" }}></TextField>
                <FormGroup>
                  <FormControlLabel
                    style={{ fontSize: 3 }}
                    control={<Checkbox checked={isChecked} onChange={handleCheckBox} disabled={eth==='' ? false:true} />}
                    label={<Typography variant="body2" color="textSecondary">{recommendedVal} Eth</Typography>}/>
                </FormGroup>
                <Button onClick={handleTransfer} variant="contained" to="#" size="large" endIcon={isLoadingMint && <Loading />} disabled={isLoadingMint}>
                  Transfer
                </Button>
              </Stack>
              <FormHelperText style={{ marginLeft: 25,display:'inline' }}>
                Eth Balance on L2 : <b>{balance}</b>
              </FormHelperText>
              <FormHelperText style={{ float: "right",marginRight:'18%' }}>
              Top up to:
              </FormHelperText>
              
              {/* <Checkbox onChange={handleCheckBox}/> */}
            </Card>
          </Grid>
        </Grid>
        <ToastContainer />
      </Container>
    </Page>
  );
}

// const tx = await signer.sendTransaction({to: addr,value: decimalToBigNumber("0.001")});
