import { Icon } from "@iconify/react";
import pieChart2Fill from "@iconify/icons-eva/pie-chart-2-fill";
import peopleFill from "@iconify/icons-eva/people-fill";
import shoppingBagFill from "@iconify/icons-eva/shopping-bag-fill";
import cashBack from "@iconify/icons-ant-design/money-collect-filled"
import approval from "@iconify/icons-ant-design/safety-certificate-filled"
import routes from "../../../constants/routes.json"
// import fileTextFill from "@iconify/icons-eva/file-text-fill";
// import lockFill from "@iconify/icons-eva/lock-fill";
// import personAddFill from "@iconify/icons-eva/person-add-fill";
// import alertTriangleFill from "@iconify/icons-eva/alert-triangle-fill";
import transferIcon from "@iconify/icons-ant-design/transaction";

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
    {
        title: "dashboard",
        path: routes.HOME,
        icon: getIcon(pieChart2Fill),
    },
    {
        title: "Mint / Burn",
        path: routes.MINT,
        icon: getIcon(cashBack),
    },
    {
        title: "Transfer",
        path: routes.TRANSFER,
        icon: getIcon(transferIcon),
    },
   
    
    
    // {
    //     title: "user",
    //     path: "/user",
    //     icon: getIcon(peopleFill),
    // },
    // {
    //     title: "product",
    //     path: "/products",
    //     icon: getIcon(shoppingBagFill),
    // },

    // {
    //     title: "blog",
    //     path: "/blog",
    //     icon: getIcon(fileTextFill),
    // },

    // {
    //   title: 'register',
    //   path: '/register',
    //   icon: getIcon(personAddFill)
    // },

    // {
    //   title: 'login',
    //   path: '/login',
    //   icon: getIcon(lockFill)
    // },

    // {
    //   title: 'Not found',
    //   path: '/404',
    //   icon: getIcon(alertTriangleFill)
    // }
];
const privatesaleDetails =[
     {
        title: "Project1",
        path: "/1",
        icon: null,
    },
     {
        title: "Project2",
        path: "/2",
        icon: null,
    },

]

export  {sidebarConfig,privatesaleDetails};
