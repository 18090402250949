import api from "../../api";
import endpoints from "../../api/endpoints";
import { StringUtils } from "../../utils/stringUtils";

export const getProject = (projectId, queryParams) => async (dispatch) => {
  // this data is not need to dispatch to to stor so we can simpally return is
  const res = await api.get(StringUtils.replaceStringBraces(endpoints.GetProject, { projectId }), { params: queryParams });
  return res.data;
};


