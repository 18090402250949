import { ethers } from 'ethers';

const metamaskProvider = async (name) => {
  try {
    // let provider;
    // if (providerName === 'metamask') {
    //   if (!window.ethereum) throw new Error('Metamask Unavailable');
    //   await window.ethereum.request({ method: 'eth_requestAccounts' });
    //   provider = new ethers.providers.Web3Provider(window.ethereum);
    //   return provider;
    // }

    // if (providerName === 'jsonrpc') {
    //   const chainId = process.env.REACT_APP_CHAIN_ID;
    //   const rpcUrls = chainId === '80001' ? 'https://rpc-mumbai.maticvigil.com' : 'https://rpc-mainnet.maticvigil.com';
    //   provider = new ethers.providers.JsonRpcProvider(rpcUrls);
    // }
    // // const chainId = process.env.REACT_APP_CHAIN_ID;
    // // const rpcUrls =
    // //   chainId === '80001' ? 'https://polygon-mumbai.infura.io/v3/96c212efca1541ff8baa75d4b8bf35a3' : 'https://polygon-mainnet.infura.io/v3/96c212efca1541ff8baa75d4b8bf35a3';
    // // const provider = new ethers.providers.JsonRpcProvider(rpcUrls);
    // return provider;
    if (!window.ethereum) throw new Error('Metamask Unavailable');
    await window.ethereum.request({ method: 'eth_requestAccounts' });
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    return provider;
  } catch (error) {
    console.log(error);
    throw error;
  }

};
export { metamaskProvider };