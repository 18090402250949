const API_END_POINTS = {
  //admin endpoints
PostUserVerify: 'user/verify',
PostUserSignin: 'user/signin',
coinGeckoApi: "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd&include_market_cap=fals&include_24hr_vol=false&include_24hr_change=false&include_last_updated_at=false",


  };
  
  export default Object.freeze(API_END_POINTS);
  