import api from "../../api";
import endpoints from "../../api/endpoints";
import { StringUtils } from "../../utils/stringUtils";

export const getAllCreators = (data) => async (dispatch) => {
  // this data is not need to dispatch to to stor so we can simpally return is
  // StringUtils.replaceStringBraces(url, params) here
  const res = await api.get(StringUtils.replaceStringBraces(endpoints.GetAllArtist, {}));
  return res.data;
};

