export class StringUtils {
  static replaceStringBraces(str, params) {
    for (const key in params) {
      if (/[a-zA-Z\_]+/g.test(str)) {
        str = str.replace(new RegExp('{(?:\\s+)?(' + key + ')(?:\\s+)?}', 'g'), params[key]);
      }
    }
    return str;
  }

  static replaceStringParams(str, params) {
    for (const key in params) {
      str = str.replace(':' + key, params[key]);
    }
    return str;
  }
  //split url by /
  static hideFooter(url) {
    let splitedUrl = url.split('/');
    if (splitedUrl && splitedUrl.length === 3) {
      splitedUrl.shift();
      let fs = splitedUrl[0];
      let ss = splitedUrl[1];
      ss = Number(ss);
      if (fs === 'art' && typeof ss === 'number') {
        return true;
      } else {
        return false;
      }
    }
  }
  //convert array of string to array of number
  static convertStringArrayToNumberArray(array) {
    if(array && array.length > 0) {
      return array.map(item => Number(item));
    }
    else {
      throw new Error('Array is empty');
    }
  }
    //convert array of numbers to array of string
  static arrayOfNumberToString(array) {
    if(array && array.length > 0) {
      return array.map(item => String(item));
    }
    else {
      throw new Error('Array is empty');
    }
  }

}
