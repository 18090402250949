const initialState = {
    eth_usd:null
   
}

const commonReducer = (state = initialState, action) => {
    switch (action.type) {
        // case 'GET_CURRENT_USER':
        //     return { ...state, currentUser: action.payload };
        // case 'UPDATE_CURRENT_USER':
        //     return { ...state, currentUser: { ...action.payload } };
        case 'COINGECKO_ETH': return { ...state, eth_usd:action.payload };
        case 'COINGECKO_MINTY': return{...state}
        default:
            return state;
    }
}

export default commonReducer;
