import jwtDecode from "jwt-decode";

export const decode = (token) => {
    try{
        const decodedToken = jwtDecode(token);
        decodedToken.isExpired = false

        if (decodedToken.exp < (new Date().getTime() / 1000)) {
            decodedToken.isExpired = true
        }

        return decodedToken;
    }catch (e) {
        return  {
            isExpired: true,
        }
    }
}