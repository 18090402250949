import { ethers } from "ethers"
import {CrossChainMessenger,MessageStatus} from "@eth-optimism/sdk";
import { metamaskProvider } from "../walletConnect";

const network = "kovan" 

// const mnemonic = process.env.MNEMONIC
// const l1Url = process.env.REACT_APP_L1_URL
// const l2Url = process.env.REACT_APP_L2_URL


// Contract addresses for DAI tokens, taken 
// from https://static.optimism.io/optimism.tokenlist.json
const daiAddrs = {
  l1Addr: "0x4f96fe3b7a6cf9725f59d353f723c1bdb64ca6aa",
  l2Addr: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1"
} 

const PECS = {
  l1Addr: process.env.REACT_APP_PECS_CONTRACT_L1,
  l2Addr: process.env.REACT_APP_PECS_CONTRACT_L2
} 

// Global variable because we need them almost everywhere
let crossChainMessenger
// Kovan (L1) and  Optimism Kovan (L2)
let l1ERC20, l2ERC20    // DAI contracts to show ERC-20 transfers
let addr    // Our address

const getSigners = async () => {
    let l1_signer;
    let l2_signer;

    const provider = await metamaskProvider();
    const network = await provider.getNetwork();
    const l1RpcProvider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_L1_URL)
    const l2RpcProvider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_L2_URL);
    // const wallet = ethers.Wallet.createRandom().connect(provider);
    // const privateKey = wallet.privateKey;

    
    // console.log('address:', wallet.address)
    // console.log('mnemonic:', wallet.mnemonic.phrase)
    // console.log('privateKey:', wallet.privateKey)
    
    if(network.name === process.env.REACT_APP_NETWORK_L1){
        l1_signer= provider.getSigner();
        l2_signer = l2RpcProvider.getSigner();
    }
    if(network.name===process.env.REACT_APP_NETWORK_L2){
        l1_signer = l1RpcProvider.getSigner();
        l2_signer =  provider.getSigner();
    }
    //===========================================================
    // const hdNode = ethers.utils.HDNode.fromMnemonic(mnemonic)
    // const privateKey = hdNode.derivePath(ethers.utils.defaultPath).privateKey
    // const l1Wallet = new ethers.Wallet(privateKey, l1RpcProvider)
    // const l2Wallet = new ethers.Wallet(privateKey, l2RpcProvider)
    //===========================================================
    // return [l1Wallet, l2Wallet]
    return [l1_signer, l2_signer]
}   


const setup = async() => {
  const [l1Signer, l2Signer] = await getSigners()
  addr = l1Signer.address;
  crossChainMessenger = new CrossChainMessenger({
      l1ChainId: process.env.REACT_APP_CHAIN_ID,   //42 For Kovan , 1 for Mainnet    
      l1SignerOrProvider: l1Signer,
      l2SignerOrProvider: l2Signer
  })
  // console.log(crossChainMessenger)
  l1ERC20 = new ethers.Contract(PECS.l1Addr, erc20ABI, l1Signer)
  l2ERC20 = new ethers.Contract(PECS.l2Addr, erc20ABI, l2Signer)  
}    // setup



// The ABI fragment for an ERC20 we need to get a user's balance.
const erc20ABI = [  
    // balanceOf
    {    
      constant: true,  
      inputs: [{ name: "_owner", type: "address" }],
      name: "balanceOf",
      outputs: [{ name: "balance", type: "uint256" }],
      type: "function",
    },
  ]    // erc20ABI



const gwei = 1000000000n
const eth = gwei * gwei   // 10^18
const centieth = eth/100n
const dai = eth   


const reportBalances = async () => {
  console.log(await crossChainMessenger.l1Signer.getBalance())
//   const l1Balance = (await crossChainMessenger.l1Signer.getBalance()).toString().slice(0,-9)
//   const l2Balance = (await crossChainMessenger.l2Signer.getBalance()).toString().slice(0,-9)
//   console.log(`On L1:${l1Balance} Gwei    On L2:${l2Balance} Gwei`)
}    


const reportERC20Balances = async () => {
  const l1Balance = (await l1ERC20.balanceOf(addr)).toString().slice(0,-18)
  const l2Balance = (await l2ERC20.balanceOf(addr)).toString().slice(0,-18)
  console.log(`DAI on L1:${l1Balance}     DAI on L2:${l2Balance}`)  
}    // reportERC20Balances





const depositETH = async (value) => {
  try {
    const start = new Date()  
    const response = await crossChainMessenger.depositETH(ethers.utils.parseEther(value))
    console.log(`Transaction hash (on L1): ${response.hash}`)
    await response.wait()
    console.log("Waiting for status to change to RELAYED")
    console.log(`Time so far ${(new Date()-start)/1000} seconds`)
    await crossChainMessenger.waitForMessageStatus(response.hash, MessageStatus.RELAYED) 
    console.log(`depositETH took ${(new Date()-start)/1000} seconds\n\n`)
  } catch (error) {
    console.log(error)
  }
 
}     



const depositERC20 = async (amount) => {
  console.log("Deposit ERC20")
  // await reportERC20Balances()
  const start = new Date()

  // Need the l2 address to know which bridge is responsible
  const allowanceResponse = await crossChainMessenger.approveERC20(PECS.l1Addr, PECS.l2Addr, ethers.utils.parseEther(amount));
  await allowanceResponse.wait()
  // console.log(`Allowance given by tx ${allowanceResponse.hash}`)
  // console.log(`Time so far ${(new Date()-start)/1000} seconds`)

  const response = await crossChainMessenger.depositERC20(PECS.l1Addr, PECS.l2Addr, ethers.utils.parseEther(amount));
  // console.log(`Deposit transaction hash (on L1): ${response.hash}`)
  await response.wait()
  // console.log("Waiting for status to change to RELAYED")
  // console.log(`Time so far ${(new Date()-start)/1000} seconds`)  
  await crossChainMessenger.waitForMessageStatus(response.hash, MessageStatus.RELAYED) 
  // await reportERC20Balances()    
  console.log(`depositERC20 took ${(new Date()-start)/1000} seconds\n\n`)
}     


const iniateWithdrawErc20 =async(amount)=>{
  amount =  ethers.utils.parseEther(amount)
  const response = await crossChainMessenger.withdrawERC20(PECS.l1Addr, PECS.l2Addr, amount)
  console.log(`Transaction hash (on L2): ${response.hash}`)
  await response.wait()
}


const withdrawERC20 = async () => { 
  
  console.log("Withdraw ERC20")
  const start = new Date()  
  await reportERC20Balances()

  const response = await crossChainMessenger.withdrawERC20(daiAddrs.l1Addr, daiAddrs.l2Addr, dai)
  console.log(`Transaction hash (on L2): ${response.hash}`)
  await response.wait()

  console.log("Waiting for status to change to IN_CHALLENGE_PERIOD")
  console.log(`Time so far ${(new Date()-start)/1000} seconds`)
  await crossChainMessenger.waitForMessageStatus(response.hash, MessageStatus.IN_CHALLENGE_PERIOD)
  console.log("In the challenge period, waiting for status READY_FOR_RELAY") 
  console.log(`Time so far ${(new Date()-start)/1000} seconds`)  
  await crossChainMessenger.waitForMessageStatus(response.hash, MessageStatus.READY_FOR_RELAY) 
  console.log("Ready for relay, finalizing message now")
  console.log(`Time so far ${(new Date()-start)/1000} seconds`)  
  await crossChainMessenger.finalizeMessage(response)
  console.log("Waiting for status to change to RELAYED")
  console.log(`Time so far ${(new Date()-start)/1000} seconds`)  
  await crossChainMessenger.waitForMessageStatus(response, MessageStatus.RELAYED)
  await reportERC20Balances()   
  console.log(`withdrawERC20 took ${(new Date()-start)/1000} seconds\n\n\n`)  
}     // withdrawERC20()


const iniateWithdrawEth =async(amount)=>{
  const response = await crossChainMessenger.withdrawETH(ethers.utils.parseEther(amount))
  await response.wait();
  
}
const finalizeWithdrawEth =async(tx)=>{
  // console.log("Waiting for status to change to IN_CHALLENGE_PERIOD")  
  // await crossChainMessenger.waitForMessageStatus(tx.hash, MessageStatus.IN_CHALLENGE_PERIOD)

  // console.log("In the challenge period, waiting for status READY_FOR_RELAY")   
  // await crossChainMessenger.waitForMessageStatus(tx.hash, MessageStatus.READY_FOR_RELAY) 

  console.log("Ready for relay, finalizing message now")  
  await crossChainMessenger.finalizeMessage(tx)

  console.log("Waiting for status to change to RELAYED")  
  await crossChainMessenger.waitForMessageStatus(tx, MessageStatus.RELAYED)
  // await reportBalances()   
  console.log(`withdrawETH done`)  
  
}

const withdrawETH = async (amount) => { 
  console.log("Withdraw ETH");
  const start = new Date()  
  console.log(`Time so far ${(new Date()-start)/1000} seconds`);
  // await reportBalances()

  const response = await crossChainMessenger.withdrawETH(ethers.utils.parseEther(amount))
  console.log(`Transaction hash (on L2): ${response.hash}`)
  await response.wait()

  console.log("Waiting for status to change to IN_CHALLENGE_PERIOD")  
  await crossChainMessenger.waitForMessageStatus(response.hash, MessageStatus.IN_CHALLENGE_PERIOD)

  console.log("In the challenge period, waiting for status READY_FOR_RELAY")   
  await crossChainMessenger.waitForMessageStatus(response.hash, MessageStatus.READY_FOR_RELAY) 

  console.log("Ready for relay, finalizing message now")  
  await crossChainMessenger.finalizeMessage(response)

  console.log("Waiting for status to change to RELAYED")  
  await crossChainMessenger.waitForMessageStatus(response, MessageStatus.RELAYED)
  // await reportBalances()   
  console.log(`withdrawETH done`)  
}     // withdrawETH()

const getTxStatus = async (tx)=>{
  let status = await crossChainMessenger?.getMessageStatus(tx);
  return {status,tx}
}

export {
    reportBalances,
    reportERC20Balances,    
     setup,
     depositETH, //  deposit ETH from Ethereum to Optimism
     withdrawETH, 
     depositERC20,   // two step process: (1)Provide an allowance to the bridge (2) Call the bridge to use the allowance to perform a transfer
     withdrawERC20, // from Optimism to Ethereum (do not require an allowance)
     iniateWithdrawEth,
     finalizeWithdrawEth,
     iniateWithdrawErc20,
     getTxStatus
}  






