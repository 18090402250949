// import { useContractCall } from "@usedapp/core";
import { ethers } from "ethers";
import { pecsAbi,manHattenAbi} from "./abi";

const abis = {
  pecs: new ethers.utils.Interface(pecsAbi),
  manHatten : new ethers.utils.Interface(manHattenAbi)
};

const addresses = {
  pecs: process.env.REACT_APP_PECS_CONTRACT_L1,
  manHatten: process.env.REACT_APP_MANHATTEN_CONTRACT_L2,

};



const getInfuraProvider = () => {
  const rpcUrls = process.env.REACT_APP_INFURA_ENDPOINT;
  const ethProvider = new ethers.providers.JsonRpcProvider(rpcUrls);
  return ethProvider;
};

const getWalletProvider = (walletName) => {
  if (!walletName) {
    throw new Error("No wallet name provided");
  }

  if (walletName === "metamask") {
    if (!window.ethereum) throw new Error("Metamask Unavailable");
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    return provider;
  }

  if (walletName === "walletConnect") {
  }
};

const makeContract = (address, abi, signer) => {
  if (!address || !abi || !signer) {
    throw new Error("Insuficient arguments");
  }
  return new ethers.Contract(address, abi, signer);
};

const asyncContractCall = async (contract, method, args, transformFunc) => {
  try {
    console.log(contract, method);
    return await contract[method](...args);
  } catch (error) {
    console.log(error);
  }
};

const metamaskProvider = async (name) => {
  try {
    // let provider;
    // if (providerName === 'metamask') {
    //   if (!window.ethereum) throw new Error('Metamask Unavailable');
    //   await window.ethereum.request({ method: 'eth_requestAccounts' });
    //   provider = new ethers.providers.Web3Provider(window.ethereum);
    //   return provider;
    // }

    // if (providerName === 'jsonrpc') {
    //   const chainId = process.env.REACT_APP_CHAIN_ID;
    //   const rpcUrls = chainId === '80001' ? 'https://rpc-mumbai.maticvigil.com' : 'https://rpc-mainnet.maticvigil.com';
    //   provider = new ethers.providers.JsonRpcProvider(rpcUrls);
    // }
    // // const chainId = process.env.REACT_APP_CHAIN_ID;
    // // const rpcUrls =
    // //   chainId === '80001' ? 'https://polygon-mumbai.infura.io/v3/96c212efca1541ff8baa75d4b8bf35a3' : 'https://polygon-mainnet.infura.io/v3/96c212efca1541ff8baa75d4b8bf35a3';
    // // const provider = new ethers.providers.JsonRpcProvider(rpcUrls);
    // return provider;
    if (!window.ethereum) throw new Error('Metamask Unavailable');
    await window.ethereum.request({ method: 'eth_requestAccounts' });
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    return provider;
  } catch (error) {
    console.log(error);
    throw error;
  }

};

export { 
  abis, 
  addresses, 
  makeContract, 
  asyncContractCall, 
  getInfuraProvider, 
  getWalletProvider,
  metamaskProvider
 };
