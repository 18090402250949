// IF : locked = true , releaseRequest != 0
// library
import { ethers } from "ethers";
import { metamaskProvider } from "./walletConnect";

// abi's
import { pecsAbi } from "./abi";
// contract addresses

// Jahanzaib modifications
const getContracts = async (contractAddress, abi) => {
  const provider = await metamaskProvider();
  const signer = provider.getSigner();
  const address = await signer.getAddress();
  const contract = new ethers.Contract(contractAddress, abi, signer);
  return { contract, provider, signer, address };
};

//============================================================================//

const pecsContract = async () => {
  const provider = await metamaskProvider();
  const signer = provider.getSigner();
  const address = await signer.getAddress();
  const contract = new ethers.Contract(process.env.REACT_APP_PECS_CONTRACT_L1, pecsAbi, signer);
  return { contract, provider, signer, address };
};

const getSigner =async()=>{
  const provider = await metamaskProvider();
  const signer = provider.getSigner();
  const address = await signer.getAddress();
  const network = await provider.getNetwork();
  return{address,signer,provider,network}
}

const getProvider = async (providerName = "metamask") => {
  try {
    let provider;
    if (providerName === "metamask") {
      if (!window.ethereum) throw new Error("Metamask Unavailable");
      await window.ethereum.enable();
      provider = new ethers.providers.Web3Provider(window.ethereum);
      return provider;
    }

    if (providerName === "jsonrpc") {
      const chainId = process.env.REACT_APP_CHAIN_ID;
      const rpcUrls = chainId === "80001" ? "https://rpc-mumbai.maticvigil.com" : "https://rpc-mainnet.maticvigil.com";
      provider = new ethers.providers.JsonRpcProvider(rpcUrls);
    }
    return provider;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export { pecsContract, getProvider,getContracts,getSigner };
