import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { Box, Button, Stack, TextField, MenuItem, FormControl, InputLabel, Select, FormHelperText, Link } from "@mui/material";
import { BlogPostCard, BlogPostsSort, BlogPostsSearch } from "../components/blog";
import { setup, depositETH, depositERC20, iniateWithdrawErc20, iniateWithdrawEth, finalizeWithdrawEth, getTxStatus } from "../blockchain/PECS";
import { getContracts, getSigner, pecsContract } from "../blockchain";
import { addresses } from "../blockchain/factory";
import { pecsAbi } from "../blockchain/abi";
import { bigNumberToDecimal, decimalToBigNumber } from "../blockchain/utils";
import Loading from "../myComponents/Loader";
// import Toaster from "./Toaster";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getUnixTime, addDays, addMinutes } from "date-fns";

//
import POSTS from "../_mocks_/blog";
import { ethers, Signer } from "ethers";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);
  const [coin, setCoin] = React.useState("");
  const [amount, setAmount] = React.useState("");
  const [txHash, setTxHash] = React.useState("");
  const [balance, setBalance] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [pendingTx, setPendingTx] = React.useState([]);
  const [isLoadingTx, setIsLoadingTx] = React.useState(false);
  const [netName, setNetName] = React.useState('');


  React.useEffect(() => {
    getTransactions();
  }, [coin, balance, isLoading]);
  // 0xcd5a5806d55b08d15bf6aecf8fe01c2478ff3990a991778447511931ba51d4b6

  const getTransactions = async () => {
    try {
      const { address, network } = await getSigner();
      setNetName(network.name)
      if (network.name === process.env.REACT_APP_NETWORK_L1) {
        setIsLoadingTx(true);
        let abi = ["event WithdrawalInitiated(address indexed _l1Token, address indexed _l2Token, address indexed _from, address _to, uint256 _amount, bytes _data)"];
        const l2Provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_L2_URL);

        const contract = new ethers.Contract("0x4200000000000000000000000000000000000010", abi, l2Provider);
        let filter = contract.filters.WithdrawalInitiated(
          process.env.REACT_APP_PECS_CONTRACT_L1, //_l1Token
          process.env.REACT_APP_PECS_CONTRACT_L1, //_l2Token
          address, //from address
        );
        const events = await contract.queryFilter(filter, 0, "latest");
        if (events) {
          await setup();
          const promises = await events.map((e) => getTxStatus(e.transactionHash));
          const transactions = await Promise.all(promises);
          console.log("transactions", transactions);

          const filteredTx = transactions.filter((t) => t.status === 2 || t.status === 4);
          console.log(filteredTx);
          setPendingTx(filteredTx);
          setIsLoadingTx(false);
        }
      }
    } catch (error) {
      console.log(error);
      isLoadingTx(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    setCoin("");
    setAmount("");
    setBalance("");
  };
  const handleDropdown = async (event) => {
    try {
      const { contract, address, provider } = await getContracts(addresses.pecs, pecsAbi);
      const network = await provider.getNetwork();
      const selectedCoin = event.target.value;

      if (value === 0) {
        if (network.name === process.env.REACT_APP_NETWORK_L1) {
          setCoin(selectedCoin);
          if (selectedCoin === "eth") {
            let ethBalance = await provider.getBalance(address);
            ethBalance = bigNumberToDecimal(ethBalance);
            setBalance(ethBalance);
          } else if (selectedCoin === "pecs") {
            let pecsBalance = await contract.balanceOf(address);
            pecsBalance = bigNumberToDecimal(pecsBalance);
            setBalance(pecsBalance);
          } else {
            setBalance("");
          }
        } else {
          setCoin("");
          setBalance("");
          toast.info("Please choose L1 network");
        }
      } else if (value === 1) {
        if (network.name === process.env.REACT_APP_NETWORK_L2) {
          setCoin(selectedCoin);
          if (selectedCoin === "eth") {
            let ethBalance = await provider.getBalance(address);
            ethBalance = bigNumberToDecimal(ethBalance);
            setBalance(ethBalance);
          } else if (selectedCoin === "pecs") {
            let pecsBalance = await contract.balanceOf(address);
            pecsBalance = bigNumberToDecimal(pecsBalance);
            setBalance(pecsBalance);
          } else {
            setBalance("");
          }
        } else {
          setCoin("");
          setBalance("");
          toast.info("Please choose L2 network");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };
  const handleTxHashChange = (e) => {
    setTxHash(e.target.value);
  };
  const handleDeposit = async () => {
    try {
      const { network } = await getSigner();
      if (!amount) toast.warn("Please enter amount");
      if (!coin) toast.info("Please select coin");
      if (value == 0 && network.name != process.env.REACT_APP_NETWORK_L1) toast.info("Please choose L1 network");

      setIsLoading(true);
      await setup();
      if (coin === "eth") {
        await depositETH(amount);
      } else {
        await depositERC20(amount);
      }
      setCoin("");
      setAmount("");
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleWithdraw = async () => {
    try {
      const { network } = await getSigner();
      if (!amount) toast.warn("Please write amount");
      if (!coin) toast.info("Pleasae select coin");
      if (value == 1 && network.name != process.env.REACT_APP_NETWORK_L2) toast.info("Please choose L2 netowrk");

      // const addedDayDate = addDays(new Date(),7);
      // const unixAfterDays = getUnixTime(addedDayDate);
      // const addMin = addMinutes(new Date(),10);
      // const unix10Mints = getUnixTime(addMin);

      await setup();
      if (coin === "eth") {
        await iniateWithdrawEth(amount);
        toast.success("Withdraw initiated change network to mainnet and claim tokens");
      } else {
        await iniateWithdrawErc20(amount);
        toast.success("Withdraw initiated change network to mainnet and claim tokens");
      }
      setCoin("");
      setAmount("");
      // console.log("=>",getUnixTime(new Date()))
      // console.log("=> unixMints",unixMints)

      // toast.success("Withdraw feature is in progress");
    } catch (error) {
      console.log(error);
    }
  };

  const finalizeWithdraw = async (tx) => {
    try {
      const { network } = await getSigner();
      if (network.name === process.env.REACT_APP_NETWORK_L1) {
        await setup();
        await finalizeWithdrawEth(tx);
        getTransactions();
      } else {
        toast.info("Please choose L1 netowrk to claim tokens");
      }

      // setTxHash('')
    } catch (error) {
      console.log(error);
    }
  };

  const openUrl = (url) => {
    window.open(url, "_blank");
  };

  return (
    <Box width={"100%"}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleTabChange}>
          <Tab label="DEPOSIT TO OPTIMISM" {...a11yProps(0)} />
          <Tab label="WITHDRAW FROM OPTIMISM" {...a11yProps(1)} />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        <Typography variant="h4" gutterBottom>
          Mainnet to Optimism
        </Typography>

        <Stack mb={5} mt={2} direction="row" alignItems="flex-start" justifyContent="space-between" spacing={3}>
          <FormControl fullWidth>
            <Select value={coin} onChange={(e) => handleDropdown(e)} displayEmpty inputProps={{ "aria-label": "Without label" }}>
              <MenuItem value="">
                <em>Please select coin</em>
              </MenuItem>
              <MenuItem value={"eth"}>ETH</MenuItem>
              <MenuItem value={"pecs"}>PECS</MenuItem>
            </Select>
            <FormHelperText style={{ marginLeft: 0 }}>Balance: {balance}</FormHelperText>
          </FormControl>

          <TextField type="number" onChange={handleAmountChange} value={amount} placeholder="amount"></TextField>
        </Stack>

        <Stack mb={5} mt={2} direction="row" alignItems="center" justifyContent="center">
          <Button onClick={handleDeposit} variant="contained" to="#" size="large" fullWidth endIcon={isLoading && <Loading />} disabled={isLoading}>
            Transfer
          </Button>
        </Stack>
      </TabPanel>

      <TabPanel value={value} index={1}>
        <Typography variant="h4" gutterBottom>
          Optimism to Mainnet
        </Typography>
        <Stack mb={5} mt={2} direction="row" alignItems="flex-start" justifyContent="space-between" spacing={3}>
          <FormControl fullWidth>
            <Select value={coin} onChange={(e) => handleDropdown(e)} displayEmpty inputProps={{ "aria-label": "Without label" }}>
              <MenuItem value="">
                <em>Pleas select coin</em>
              </MenuItem>
              {/* <MenuItem value={"eth"}>ETH</MenuItem> */}
              <MenuItem value={"pecs"}>PECS</MenuItem>
            </Select>
            <FormHelperText style={{ marginLeft: 0 }}>Balance: {balance}</FormHelperText>
          </FormControl>

          <TextField type="number" onChange={handleAmountChange} value={amount} placeholder="amount"></TextField>
        </Stack>
        <Stack mb={5} mt={2} direction="row" alignItems="center" justifyContent="center">
          <Button onClick={handleWithdraw} variant="contained" to="#" size="large" fullWidth endIcon={isLoading && <Loading />} disabled={isLoading}>
            Initiate Withdraw
          </Button>
        </Stack>

        {isLoadingTx && (
          <Stack mb={5} mt={5} direction="row" alignItems="center" justifyContent="center">
            <Loading size={50} color="primary" />
          </Stack>
        )}

        { netName === process.env.REACT_APP_NETWORK_L1 ? (
          <>
          {pendingTx.length ? pendingTx.map((p, i) => (
            <Stack mb={5} mt={2} direction="row" alignItems="flex-start" justifyContent="center" spacing={3} key={i}>
              <Link underline="hover" onClick={() => openUrl(process.env.REACT_APP_ETHERSCAN_URL_L2 + "/" + p.tx)} style={{ cursor: "pointer" }}>
                {p.tx}
              </Link>
              <Button onClick={() => finalizeWithdraw(p.tx)} variant="contained" to="#" size="large" disabled={p.status === 2 ? true : false}>
                {p.status === 4 ? "Claim" : p.status === 2 ? "Pending" : "Not found"}
              </Button>
            </Stack>
            )) :
            <Stack mb={5} mt={5} direction="row" alignItems="center" justifyContent="center">
            <Typography variant="h4" gutterBottom>
              No transaction found
            </Typography>
          </Stack>
          }  
          </>
          
        ) : (
          <Stack mb={5} mt={5} direction="row" alignItems="center" justifyContent="center">
            <Typography variant="h4" gutterBottom>
              Please change network to L1/Mainnet to see transactions
            </Typography>
          </Stack>
        )}
      </TabPanel>
      <ToastContainer />
    </Box>
  );
}
