const initialState = {
    role: null,
    email: null,
    nickname: null,
    token: null,
    loggedIn: false,
    isPatron: false,
    isVerified: false,
    joined: null,
    address: null,
    aboutMe: null,
    portfolioLink: null,
    firstTimeLogin: false,
    ethProviderName: 'jsonrpc',
    isOnCorrectNetwork: false,
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        // case 'GET_CURRENT_USER':
        //     return { ...state, currentUser: action.payload };
        // case 'UPDATE_CURRENT_USER':
        //     return { ...state, currentUser: { ...action.payload } };
        case 'LOGIN_USER':
            return { ...state, ...action.payload };
        case 'LOGOUT_USER':
            return initialState;
        default:
            return state;
    }
}

export default authReducer;
