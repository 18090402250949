import { ethers } from "ethers";
import { useState, useEffect } from "react";
import { Navigate, useRoutes, useNavigate } from "react-router-dom";
// layouts
// import DashboardLayout from "./layouts/dashboard";
import DashboardLayout from "./components/common/dashboardLayout/";
import LogoOnlyLayout from "./components/common/LogoOnlyLayout";
//
import Login from "./pages/Login";
// import Register from "./pages/Register";
import DashboardApp from "./pages/DashboardApp";
import Mint from "./pages/Mint";
import Transfer from "./pages/Transfer";
import Products from "./pages/Products";
import Blog from "./pages/Blog";
import User from "./pages/User";
import NotFound from "./pages/Page404";

import useActions from "./store/hooks/useActions";
import routes from "./constants/routes.json";
import { decode } from "./utils/jwt";
import { getSigner } from "./blockchain";


// ----------------------------------------------------------------------

const AuthGuard = ({ children }) => {
  const { logoutUser,getCoinGeckoApiData } = useActions();
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  useEffect(() => {
    // const session = JSON.parse(localStorage.getItem("persist:session"));
    if (window.ethereum !== undefined) {
      window.ethereum.on("accountsChanged", (accounts) => {
        // logoutUser();
        // setIsAuthenticated(false);
      });
      window.ethereum.on("chainChanged", (chainId) => {
        chainId = ethers.BigNumber.from(chainId).toString();
        if (chainId !== process.env.REACT_APP_CHAIN_ID) {
          // logoutUser();
          // setIsAuthenticated(false);
        }
      });
    } else {
      console.log(window.ethereum);

    }

    const token = localStorage.getItem("token");
    if (token !== null && token !== "") {
      getCoinGeckoApiData();
      const decodedToken = decode(token);
      if (decodedToken.isExpired) {
        alert("Token has been expired");
        logoutUser();
        // navigate(routes.LOGIN);
      }
    }

    if (!token) setIsAuthenticated(true);// only chnage this true to false if you want to add auth guard for login users
  }, [isAuthenticated, window.location.pathname]);

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return <>{children}</>;
};
//AuthGuard End



const GuestGuard = ({ children, apply }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // const session = JSON.parse(localStorage.getItem("persist:session"));
    const token = localStorage.getItem("token");
    if (token) {
      setIsAuthenticated(true);
    }
  }, [isAuthenticated]);

  if (!apply) {
    return <>{children}</>;
  }

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }
  return <>{children}</>;
};
// GuestGuard End

export default function Router() {
  const [addr, setAddr] = useState('');
  const [net, setNet] = useState('');

  useEffect(()=>{
    if (window.ethereum !== undefined) {
      window.ethereum.on("accountsChanged", (accounts) => {
        console.log("accounts =>" ,accounts)
        window.location.reload();
      });
      window.ethereum.on("chainChanged", (chainId) => {
        console.log("chainId =>",chainId);
        window.location.reload();
      });
    }
    getUser();

  },[]);
  const getUser =async()=>{
    const {address,network} = await getSigner();
    setAddr(address);
    setNet(network.name);
  } 

  return useRoutes([
    {
      path: "/",
      element: <DashboardLayout address={addr} net={net} />,
      children: [
        // { element: <Navigate to="/" replace /> },
        { path: routes.HOME, element: <DashboardApp /> },
        { path: routes.MINT, element: <Mint /> },
        { path: routes.TRANSFER, element: <Transfer /> },
        //theme routes
        { path: "user", element: <User /> },
        { path: "products", element: <Products /> },
        { path: "blog", element: <Blog /> },
      ].map((chld) => ({ ...chld, element: <AuthGuard>{chld.element}</AuthGuard> })),
    },
    {
      path: "/",
      // element: <LogoOnlyLayout />,
      children: [
        // { path: "login", element: <Login />, gaurd: true },
        { path: "404", element: <NotFound />, gaurd: false },
        { path: "*", element: <Navigate to="/404" /> },
      ].map((chld) => ({ ...chld, element: <GuestGuard apply={chld.gaurd}>{chld.element}</GuestGuard> })),
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
