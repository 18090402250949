import { ethers } from "ethers";
import { metamaskProvider } from "../walletConnect";

export const getMMProvider = async () => {
  const provider = await metamaskProvider();
  const signer = provider.getSigner();
  const address = await signer.getAddress();
  return { provider, signer, address };
};

export const bigNumberToDecimal = (amount) => ethers.utils.formatEther(amount);
export const decimalToBigNumber = (amount) => ethers.utils.parseEther(amount);

export const isCorrectNetwork = async (networkName) => {
  const provider = await metamaskProvider();
  const network = await provider.getNetwork();
  if (network.name === networkName) {
    return true;
  }
  return false;
};
